import React from "react";
import * as SC from "./styles";

export const Content = () => {
  return (
    <div>
      <SC.Content>
        <SC.Title>Privacy Policy</SC.Title>
        <SC.P>Last updated: November 10, 2021</SC.P>
        <SC.P>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </SC.P>
        <SC.P>
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy. This Privacy Policy has been
          created with the help of the Privacy Policy Generator.
        </SC.P>
        <SC.Title>Interpretation and Definitions</SC.Title>
        <SC.Title2>Interpretation</SC.Title2>
        <SC.P>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </SC.P>
        <SC.Title>Definitions</SC.Title>
        <SC.P>For the purposes of this Privacy Policy:</SC.P>
        <ul>
          <SC.List>
            <SC.Title4>Account</SC.Title4> means a unique account created for
            You to access our Service or parts of our Service.
          </SC.List>
          <SC.List>
            <SC.Title4>Affiliate</SC.Title4> means an entity that controls, is
            controlled by or is under common control with a party, where
            "control" means ownership of 50% or more of the shares, equity
            interest or other securities entitled to vote for election of
            directors or other managing authority.
          </SC.List>
          <SC.List>
            <SC.Title4>Application</SC.Title4> means the software program
            provided by the Company downloaded by You on any electronic device,
            named ARIAMEET.
          </SC.List>
          <SC.List>
            <SC.Title4>Company</SC.Title4> (referred to as either "the Company",
            "We", "Us" or "Our" in this Agreement) refers to LYA ELECTRONIC SAS,
            CARRERA 65 #79-121.
          </SC.List>
          <SC.List>
            <SC.Title4>Country</SC.Title4> refers to: Colombia
          </SC.List>
          <SC.List>
            <SC.Title4>Device</SC.Title4> means any device that can access the
            Service such as a computer, a cellphone or a digital tablet.
          </SC.List>
          <SC.List>
            <SC.Title4>Personal Data</SC.Title4> is any information that relates
            to an identified or identifiable individual.
          </SC.List>
          <SC.List>
            <SC.Title4>Service</SC.Title4> refers to the Application.
          </SC.List>
          <SC.List>
            <SC.Title4>Service Provider</SC.Title4> means any natural or legal
            person who processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used.
          </SC.List>
          <SC.List>
            <SC.Title4>Usage Data</SC.Title4> refers to data collected
            automatically, either generated by the use of the Service or from
            the Service infrastructure itself (for example, the duration of a
            page visit).
          </SC.List>
          <SC.List>
            <SC.Title4>You</SC.Title4> means the individual accessing or using
            the Service, or the company, or other legal entity on behalf of
            which such individual is accessing or using the Service, as
            applicable.
          </SC.List>
        </ul>
        <SC.Title>Collecting and Using Your Personal Data</SC.Title>
        <SC.Title2>Types of Data Collected</SC.Title2>
        <SC.Title3>Personal Data</SC.Title3>
        <SC.P>
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </SC.P>
        <ul>
          <SC.List>Email address</SC.List>
          <SC.List>First name and last name</SC.List>
          <SC.List>Phone number</SC.List>
          <SC.List>Address, State, Province, ZIP/Postal code, City</SC.List>
          <SC.List>Usage Data</SC.List>
        </ul>
        <SC.Title2>Usage Data</SC.Title2>
        <SC.P>
          Usage Data is collected automatically when using the Service.
        </SC.P>
        <SC.P>
          Usage Data may include information such as Your Device's Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </SC.P>
        <SC.P>
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </SC.P>
        <SC.P>
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </SC.P>
        <SC.Title2>Information Collected while Using the Application</SC.Title2>
        <SC.P>
          While using Our Application, in order to provide features of Our
          Application, We may collect, with Your prior permission:
        </SC.P>
        <ul>
          <SC.List>Information regarding your location</SC.List>
          <SC.List>
            Information from your Device's phone book (contacts list)
          </SC.List>
          <SC.List>
            Pictures and other information from your Device's camera and photo
            library
          </SC.List>
        </ul>
        <SC.P>
          We use this information to provide features of Our Service, to improve
          and customize Our Service. The information may be uploaded to the
          Company's servers and/or a Service Provider's server or it may be
          simply stored on Your device.
        </SC.P>
        <SC.P>
          You can enable or disable access to this information at any time,
          through Your Device settings.
        </SC.P>
        <SC.Title>Use of Your Personal Data</SC.Title>
        <SC.P>
          The Company may use Personal Data for the following purposes:
        </SC.P>
        <ul>
          <SC.List>
            <SC.Title4>To provide and maintain our Service</SC.Title4>,
            including to monitor the usage of our Service.
          </SC.List>
          <SC.List>
            <SC.Title4>To manage Your Account:</SC.Title4>to manage Your
            registration as a user of the Service. The Personal Data You provide
            can give You access to different functionalities of the Service that
            are available to You as a registered user.
          </SC.List>
          <SC.List>
            <SC.Title4>For the performance of a contract:</SC.Title4> the
            development, compliance and undertaking of the purchase contract for
            the products, items or services You have purchased or of any other
            contract with Us through the Service.
          </SC.List>
          <SC.List>
            <SC.Title4>To contact You:</SC.Title4> To contact You by email,
            telephone calls, SMS, or other equivalent forms of electronic
            communication, such as a mobile application's push notifications
            regarding updates or informative communications related to the
            functionalities, products or contracted services, including the
            security updates, when necessary or reasonable for their
            implementation.
          </SC.List>
          <SC.List>
            <SC.Title4>To provide You</SC.Title4> with news, special offers and
            general information about other goods, services and events which we
            offer that are similar to those that you have already purchased or
            enquired about unless You have opted not to receive such
            information.
          </SC.List>
          <SC.List>
            <SC.Title4>For business transfers:</SC.Title4> We may use Your
            information to evaluate or conduct a merger, divestiture,
            restructuring, reorganization, dissolution, or other sale or
            transfer of some or all of Our assets, whether as a going concern or
            as part of bankruptcy, liquidation, or similar proceeding, in which
            Personal Data held by Us about our Service users is among the assets
            transferred.
          </SC.List>
          <SC.List>
            <SC.Title4>For other purposes:</SC.Title4> We may use Your
            information for other purposes, such as data analysis, identifying
            usage trends, determining the effectiveness of our promotional
            campaigns and to evaluate and improve our Service, products,
            services, marketing and your experience.
          </SC.List>
        </ul>
        <SC.P>
          We may share Your personal information in the following situations:
        </SC.P>
        <ul>
          <SC.List>
            <SC.Title4>With Service Providers:</SC.Title4> We may share Your
            personal information with Service Providers to monitor and analyze
            the use of our Service, to contact You.
          </SC.List>
          <SC.List>
            <SC.Title4>For business transfers:</SC.Title4> We may share or
            transfer Your personal information in connection with, or during
            negotiations of, any merger, sale of Company assets, financing, or
            acquisition of all or a portion of Our business to another company.
          </SC.List>
          <SC.List>
            <SC.Title4>With Affiliates:</SC.Title4> We may share Your
            information with Our affiliates, in which case we will require those
            affiliates to honor this Privacy Policy. Affiliates include Our
            parent company and any other subsidiaries, joint venture partners or
            other companies that We control or that are under common control
            with Us.
          </SC.List>
          <SC.List>
            <SC.Title4>With business partners:</SC.Title4> We may share Your
            information with Our business partners to offer You certain
            products, services or promotions.
          </SC.List>
          <SC.List>
            <SC.Title4>With other users:</SC.Title4> when You share personal
            information or otherwise interact in the public areas with other
            users, such information may be viewed by all users and may be
            publicly distributed outside.
          </SC.List>
          <SC.List>
            <SC.Title4>With Your consent:</SC.Title4> We may disclose Your
            personal information for any other purpose with Your consent.
          </SC.List>
        </ul>
        <SC.Title>Retention of Your Personal Data</SC.Title>
        <SC.P>
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </SC.P>
        <SC.P>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </SC.P>
        <SC.Title>Transfer of Your Personal Data</SC.Title>
        <SC.P>
          Your information, including Personal Data, is processed at the
          Company's operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </SC.P>
        <SC.P>
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </SC.P>
        <SC.P>
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </SC.P>
        <SC.Title>Disclosure of Your Personal Data</SC.Title>
        <SC.Title2>Business Transactions</SC.Title2>
        <SC.P>
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </SC.P>
        <SC.Title2>Law enforcement</SC.Title2>
        <SC.P>
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </SC.P>
        <SC.Title2>Other legal requirements</SC.Title2>
        <SC.P>
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </SC.P>
        <ul>
          <SC.List>Comply with a legal obligation</SC.List>
          <SC.List>
            Protect and defend the rights or property of the Company
          </SC.List>
          <SC.List>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </SC.List>
          <SC.List>
            Protect the personal safety of Users of the Service or the public
          </SC.List>
          <SC.List>Protect against legal liability</SC.List>
        </ul>
        <SC.Title>Security of Your Personal Data</SC.Title>
        <SC.P>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </SC.P>
        <SC.Title>Children's Privacy</SC.Title>
        <SC.P>
          Our Service does not address anyone under the age of 13. We do not
          knowingly collect personally identifiable information from anyone
          under the age of 13. If You are a parent or guardian and You are aware
          that Your child has provided Us with Personal Data, please contact Us.
          If We become aware that We have collected Personal Data from anyone
          under the age of 13 without verification of parental consent, We take
          steps to remove that information from Our servers.
        </SC.P>
        <SC.P>
          If We need to rely on consent as a legal basis for processing Your
          information and Your country requires consent from a parent, We may
          require Your parent's consent before We collect and use that
          information.
        </SC.P>
        <SC.Title>Links to Other Websites</SC.Title>
        <SC.P>
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party's site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </SC.P>
        <SC.P>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </SC.P>
        <SC.Title>Changes to this Privacy Policy</SC.Title>
        <SC.P>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </SC.P>
        <SC.P>
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the "Last
          updated" date at the top of this Privacy Policy.
        </SC.P>
        <SC.P>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </SC.P>
        <SC.Title>Contact Us</SC.Title>
        <SC.P>
          If you have any questions about this Privacy Policy, You can contact us:
        </SC.P>
        <ul>
          <SC.List>By email: info@lya-electtronic.com</SC.List>
        </ul>
      </SC.Content>
    </div>
  );
};
