import styled from "styled-components";

export const Content = styled.div``;

export const Title = styled.span`
  font-size: 35px;
  color: rgb(63, 74, 89);
  font-weight: 500;
  display: block;
  margin-top: 10px;
`;

export const Title2 = styled.span`
  font-size: 30px;
  color: rgb(63, 74, 89);
  font-weight: 500;
  display: block;
  margin-top: 20px;
`;

export const Title3 = styled.span`
  font-size: 20px;
  color: rgb(63, 74, 89);
  font-weight: 500;
  display: block;
  margin-top: 20px;
`;

export const P = styled.p`
  color: rgb(112, 112, 112);
  margin-top: 20px;
  margin-bottom: 20px;
  letter-spacing: 0.8px;
  font-size: 15px;
`;

export const List = styled.li`
  color: rgb(112, 112, 112);
  margin-bottom: 30px;
  letter-spacing: 0.8px;
  font-size: 15px;
`;

export const Title4 = styled.span`
  color: rgb(63, 74, 89);
  font-weight: bold;
`;
