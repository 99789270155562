import React from "react";
import "./App.css";

import styled from "styled-components";
import { Content } from "./components/Content/Content";
import SvgComponent from "./assets/Halcon";
import img from './assets/Halcon.png';

function App() {
  
  const ContentPrincipal = styled.div`
    width: 50%;
    height: 600px;
    padding: 20px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    overflow: auto;
    box-shadow: #00000029 0 3px 6px;

    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #0989c9;
      border-radius: 10px;
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: rgba(211, 208, 208, 0.8);
      border-radius: 10px;
      margin-block: 10px;
    }
  `;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "96vh",
      }}
    >
      <div style={{marginTop: "15px", marginBottom: "10px"}}>
        {/* <SvgComponent></SvgComponent> */}
        <img src={img} alt="Halcon" width="90"/>
      </div>
      <ContentPrincipal>
        <Content></Content>
      </ContentPrincipal>
    </div>
  );
}

export default App;
